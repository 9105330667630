import { TreeNode } from "../../Types";
import Integers from "../../genericNodeTypes/Integers";
import ReactMarkdown from "react-markdown";
import { Col, Row } from "reactstrap";
import Markdown from "../../genericNodeTypes/Markdown";
import { SetStateAction } from "react";

const SRCharacterNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return (
    <>
      <Row>
        {node.Children.filter(
          (child: TreeNode) => child.Name === "Portrait"
        ).map((child: TreeNode) => (
          <Col xs="auto" key={"j3gdhs" + child.ID}>
            {Markdown(
              child,
              load_node,
              handleTempContentChange,
              temporaryContent
            )}
          </Col>
        ))}
        <Col>
          <ReactMarkdown>{node.Content}</ReactMarkdown>
        </Col>
        <Row>
          {node.Children.filter(
            (child: TreeNode) => child.Type === "Integers"
          ).map((child: TreeNode) => (
            <Col xs="auto" key={"jkdhs" + child.ID}>
              <h3>{child.Name}</h3>
              {Integers(
                child,
                (new_id) => load_node(node.ID),
                handleTempContentChange,
                temporaryContent
              )}
            </Col>
          ))}
        </Row>
      </Row>
    </>
  );
};
export default SRCharacterNode;
