import { Button, ButtonGroup } from "reactstrap";
import { TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { SetStateAction } from "react";

const IntegerNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return (
    <ButtonGroup>
      <Button
        outline
        onClick={() => {
          backendService
            .change_content(node.ID, "" + (+node.Content - 1))
            .then((res) => {
              load_node(node.ID);
            });
        }}
      >
        -
      </Button>
      <Button outline disabled>
        {node.Content}
      </Button>
      <Button
        outline
        onClick={() => {
          backendService
            .change_content(node.ID, "" + (+node.Content + 1))
            .then((res) => {
              load_node(node.ID);
            });
        }}
      >
        +
      </Button>
    </ButtonGroup>
  );
};
export default IntegerNode;
