import { Button, ButtonGroup, Col } from "reactstrap";
import { TreeNode } from "../Types";
import ReactMarkdown from "react-markdown";
import Integer from "./Integer";
import { SetStateAction } from "react";

const IntegersNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return (
    <Col>
      <ReactMarkdown key={"xx112e3" + node.ID}>{node.Content}</ReactMarkdown>
      <ButtonGroup vertical key={"112e3" + node.ID}>
        {node.Children.filter(
          (child: TreeNode) => child.Type === "Integer"
        ).map((child: TreeNode) => (
          <ButtonGroup key={"2e3" + child.ID}>
            <Button
              disabled
              outline
              key={"kj3" + child.ID}
              style={{
                textAlign: "left",
              }}
            >
              {child.Name}
            </Button>
            {Integer(
              child,
              (new_id) => load_node(node.ID),
              handleTempContentChange,
              temporaryContent
            )}
          </ButtonGroup>
        ))}
      </ButtonGroup>
    </Col>
  );
};
export default IntegersNode;
