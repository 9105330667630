import { Button, ButtonGroup, Col } from "reactstrap";
import { TreeNode } from "../Types";
import ReactMarkdown from "react-markdown";
import Bool from "./Bool";
import { SetStateAction } from "react";

const BoolsNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return (
    <Col>
      <ReactMarkdown key={"xx12412e3" + node.ID}>{node.Content}</ReactMarkdown>
      <ButtonGroup vertical key={"11222e3" + node.ID}>
        {node.Children.filter((child: TreeNode) => child.Type === "Bool").map(
          (child: TreeNode) => (
            <ButtonGroup key={"34342e3" + child.ID}>
              <Button
                disabled
                outline
                key={"k5432j3" + child.ID}
                style={{
                  textAlign: "left",
                  width: "100%",
                }}
              >
                {child.Name}
              </Button>
              {Bool(
                child,
                (new_id) => load_node(node.ID),
                handleTempContentChange,
                temporaryContent
              )}
            </ButtonGroup>
          )
        )}
      </ButtonGroup>
    </Col>
  );
};
export default BoolsNode;
