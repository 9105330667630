import { SetStateAction } from "react";
import { TreeNode } from "../Types";
import ReactMarkdown from "react-markdown";

const MarkdownNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return <ReactMarkdown>{node.Content}</ReactMarkdown>;
};
export default MarkdownNode;
