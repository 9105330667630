import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { TreeNode } from "../../Types";
import ReactMarkdown from "react-markdown";
import SRCharacterNode from "./SRCharacter";
import { SetStateAction } from "react";

const SRCharactersNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return (
    <Col>
      <Row>
        <ReactMarkdown key={"2fxx112e3" + node.ID}>
          {node.Content}
        </ReactMarkdown>
      </Row>
      <Row>
        {node.Children.filter(
          (child: TreeNode) => child.Type === "SR Character"
        ).map((child: TreeNode) => (
          <Card
            key={"gzu7fzf7" + child.ID}
            style={{
              width: "24rem",
            }}
          >
            <CardBody>
              <Button
                onClick={() => {
                  load_node(child.ID);
                }}
                outline
                key={"kaej3" + child.ID}
                style={{
                  textAlign: "left",
                }}
              >
                {child.Name}
              </Button>
              {SRCharacterNode(
                child,
                (new_id) => load_node(node.ID),
                handleTempContentChange,
                temporaryContent
              )}
            </CardBody>
          </Card>
        ))}
      </Row>
    </Col>
  );
};
export default SRCharactersNode;
