import { Button, ButtonGroup, Col, Row } from "reactstrap";
import { TreeNode } from "../Types";
import ReactMarkdown from "react-markdown";
import { SetStateAction } from "react";

const DefaultNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: {
      value: SetStateAction<string>;
    };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return (
    <Row>
      <Col>
        <ReactMarkdown>{node.Content}</ReactMarkdown>
      </Col>
      <Col xs="auto">
        <ButtonGroup vertical>
          {node.Children.map((child: TreeNode) => (
            <Button
              key={"3f3f" + child.ID}
              outline
              onClick={() => {
                load_node(child.ID);
              }}
            >
              {child.Name}
            </Button>
          ))}
        </ButtonGroup>
      </Col>
    </Row>
  );
};
export default DefaultNode;
