import React, { Component } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TreeNode from "./components/TreeNode";

const router = createBrowserRouter([
  {
    path: "/",
    element: <TreeNode />,
  },
  {
    path: "node/:id/:depth/:pathmax",
    element: <TreeNode />,
  },
]);

class App extends Component {
  render() {
    return (
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    );
  }
}

export default App;
