import { Button, ButtonGroup } from "reactstrap";
import { TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { SetStateAction } from "react";

const BoolNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return (
    <Button
      active={node.Content === "true"}
      outline
      color={node.Content === "true" ? "success" : "danger"}
      key={"928cn" + node.ID}
      //style={{width: "100%"}}
      onClick={() => {
        backendService
          .change_content(node.ID, "" + !(node.Content === "true"))
          .then((res) => {
            load_node(node.ID);
          });
      }}
    >
      {node.Content === "true" ? <>☑</> : <>☐</>}
    </Button>
  );
};
export default BoolNode;
