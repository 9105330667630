import { Button, ButtonGroup, Col, Input, Row } from "reactstrap";
import { TreeNode } from "../Types";
import { SetStateAction, useEffect } from "react";
import backendService from "../../services/backend.service";

const EditorNode = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: {
      value: SetStateAction<string>;
    };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return (
    <Row>
      <Col><Input
        type="textarea"
        onChange={handleTempContentChange}
        value={temporaryContent.toString()}
        rows={node.Content.split(/\r\n|\r|\n/).length + 3}
      ></Input>
      <Button
        outline
        onClick={() => {
          backendService.change_content(node.ID, temporaryContent.toString());
        }}
      >
        💾
      </Button>
      </Col>
      <Col xs="auto">
        <ButtonGroup vertical>
          {node.Children.map((child: TreeNode) => (
            <Button
              key={"36ff3f" + child.ID}
              outline
              onClick={() => {
                load_node(child.ID);
              }}
            >
              {child.Name}
            </Button>
          ))}
        </ButtonGroup>
      </Col>
    </Row>
  );
};
export default EditorNode;
