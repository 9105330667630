import MarkdownNode from "./genericNodeTypes/Markdown";
import EditorNode from "./genericNodeTypes/Editor";
import UnknownTypeNode from "./genericNodeTypes/UnknownType";
import DefaultNode from "./genericNodeTypes/Default";
import IntegerNode from "./genericNodeTypes/Integer";
import IntegersNode from "./genericNodeTypes/Integers";
import SRCharacterNode from "./usecaseNodeTypes/Shadowrun/SRCharacter";
import SRCharactersNode from "./usecaseNodeTypes/Shadowrun/SRCharacters";
import { SetStateAction } from "react";
import BoolNode from "./genericNodeTypes/Bool";
import BoolsNode from "./genericNodeTypes/Bools";

type TreeNode = {
  ID: string;
  Name: string;
  Type: string;
  Content: string;
  Children: never[];
  PathToRoot: never[];
};

export const constuctor_dict = new Map<
  string,
  (
    node: TreeNode,
    load_node: (new_id: string) => void,
    handleTempContentChange: (event: {
      target: { value: SetStateAction<string> };
    }) => void,
    temporaryContent: SetStateAction<string>
  ) => JSX.Element
>();

export const type_prompt_dict = new Map<string, string[]>();



constuctor_dict.set("Editor", EditorNode);
type_prompt_dict.set("Editor", ["Improve", "Summarize", "Image"]);

constuctor_dict.set("Markdown", MarkdownNode);
type_prompt_dict.set("Markdown", ["Improve", "Summarize", "Image"]);

constuctor_dict.set("Default", DefaultNode);
type_prompt_dict.set("Default", ["Improve", "Summarize", "Image"]);

constuctor_dict.set("Integer", IntegerNode);
type_prompt_dict.set("Integer", []);

constuctor_dict.set("Integers", IntegersNode);
type_prompt_dict.set("Integers", []);

constuctor_dict.set("Bool", BoolNode);
type_prompt_dict.set("Bool", []);

constuctor_dict.set("Bools", BoolsNode);
type_prompt_dict.set("Bools", []);

constuctor_dict.set("SR Character", SRCharacterNode);
type_prompt_dict.set("SR Character", ["Improve Description", "Portrait"]);

constuctor_dict.set("SR Characters", SRCharactersNode);
type_prompt_dict.set("SR Characters", ["Improve Description"]);

export const prompt_prefix_dict = new Map<string, string>();
export const prompt_postfix_dict = new Map<string, string>();

prompt_prefix_dict.set(
  "Improve",
  "Improve the following description and use markdown notation: "
);
prompt_postfix_dict.set("Improve", "");

prompt_prefix_dict.set(
  "Summarize",
  "Summarize the following description with markdown notation: "
);
prompt_postfix_dict.set("Summarize", "");

prompt_prefix_dict.set(
  "Image",
  "Create an evocative image to go with this description: "
);
prompt_postfix_dict.set("Image", "");

prompt_prefix_dict.set(
  "Improve Description",
  "Improve the following description and use markdown notation: "
);
prompt_postfix_dict.set("Improve Description", "");

prompt_prefix_dict.set(
  "Portrait",
  "Create a portrait image to go with this character description: "
);
prompt_postfix_dict.set("Portrait", "");

export const node_to_component = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  const constructor = constuctor_dict.get(node.Type);
  if (constructor !== undefined) {
    return constructor(
      node,
      load_node,
      handleTempContentChange,
      temporaryContent
    );
  } else {
    return UnknownTypeNode(node);
  }
};

export type { TreeNode };
