import {
  ChangeEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import ReconnectingWebSocket from "reconnecting-websocket";
import backendService from "../services/backend.service";
import {
  TreeNode,
  constuctor_dict,
  node_to_component,
  prompt_postfix_dict,
  prompt_prefix_dict,
  type_prompt_dict,
} from "./Types";
import Editor from "./genericNodeTypes/Editor";
import { BACKEND_URL, WEBSOCKET_URL } from "../constants";

const module_by_type = (
  node: TreeNode,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void,
  temporaryContent: SetStateAction<string>
) => {
  return node_to_component(
    node,
    load_node,
    handleTempContentChange,
    temporaryContent
  );
};

const cap_length = (name: string) => {
  if (name.length > 12) {
    return name.substring(0, 9) + "...";
  } else {
    return name;
  }
};

const copy_tree_to_clipboard = (node: TreeNode) => {
  let json_blob = {
    Name: node.Name,
    Type: node.Type,
    Description: node.Content,
    Items: [],
  };

  const recursive_fill = (
    parentJson: {
      Name: string;
      Type: string;
      Description: string;
      Items: {}[];
    },
    node: TreeNode
  ) => {
    node.Children.forEach((child: TreeNode) => {
      let newChild = {
        Name: child.Name,
        Type: child.Type,
        Description: child.Content,
        Items: [],
      };
      recursive_fill(newChild, child);
      parentJson["Items"].push(newChild);
    });
  };

  recursive_fill(json_blob, node);
  return json_blob;
};

const TypeRouter = () => {
  let { id, depth, pathmax } = useParams();
  if (id === undefined) {
    id = "711318fd-ff4c-4670-96b4-0ad876281183";
  }
  if (depth === undefined) {
    depth = "3";
  }
  if (pathmax === undefined) {
    pathmax = "5";
  }

  const [node, setNode] = useState({
    ID: id,
    Name: "loading",
    Type: "loading",
    Content: "loading",
    Children: [],
    PathToRoot: [],
  });

  const navigate = useNavigate();

  function load_node(new_id: string) {
    backendService.get(new_id).then((res) => {
      const new_node = res.data;
      setNode(new_node);
      setNewNodeName(new_node.Name);
      setTemporaryContent(new_node.Content);

      const user = JSON.parse(localStorage.getItem("user") || "false");
      if (user) {
        setIsLoggedIn(true);
      }
    });
  }

  if (node.Type === "loading") {
    load_node(id);
  }

  let node_header = (
    <Container>
      loading ... {id} - {depth} - {pathmax}
    </Container>
  );

  const type_list = Array.from(constuctor_dict.keys());
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const toggleType = () => {
    setTypeDropdownOpen(!typeDropdownOpen);
  };

  let prompts = type_prompt_dict.get(node.Type);
  if (prompts === undefined) {
    prompts = [];
  }
  const [promptDropdownOpen, setPromptDropdownOpen] = useState(false);
  const togglepPrompt = () => {
    setPromptDropdownOpen(!promptDropdownOpen);
  };

  const [childrenDropdownOpen, setChildrenDropdownOpen] = useState(false);
  const togglepChildren = () => {
    setChildrenDropdownOpen(!childrenDropdownOpen);
  };

  const [newNodeName, setNewNodeName] = useState(node.Name);

  const [userName, setUserName] = useState("");

  const handleNameInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    let new_name = event.target.value;
    setNewNodeName(new_name);
  };

  const [editMode, setEditMode] = useState(false);
  const togglepEditMode = () => {
    setEditMode(!editMode);
  };

  const [temporaryContent, setTemporaryContent] = useState(node.Content);

  const handleTempContentChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    let new_content = event.target.value;
    setTemporaryContent(new_content);
  };

  useEffect(() => {
    navigate("/node/" + node.ID + "/3/2");
    setTemporaryContent(node.Content);
  }, [node, navigate, setTemporaryContent]);

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const socketRef = useRef<ReconnectingWebSocket | null>(null);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isWaitingForAI, setIsWaitingForAI] = useState<boolean>(false);

  const [openLoginModal, setOpenLoginModal] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "false");
    if (user && isLoggedIn) {
      setUserName(user["name"]);
    } else {
      if (editMode) {
        setEditMode(!editMode);
      }
      setUserName("");
    }
  }, [isLoggedIn, editMode, setEditMode]);

  useEffect(() => {
    socketRef.current = new ReconnectingWebSocket(
      WEBSOCKET_URL + "data_updates/" + id
    );

    socketRef.current.onopen = () => {
      setIsConnected(true);
    };

    socketRef.current.onclose = () => {
      setIsConnected(false);
    };

    socketRef.current.onmessage = (event) => {
      try {
        const new_node = JSON.parse(event.data);
        if ("new_content" in new_node) {
          setIsWaitingForAI(false);
          if (new_node["id"] === id) {
            setTemporaryContent(new_node["new_content"]);
          }
        } else {
          setNode(new_node);
          setNewNodeName(new_node.Name);
          setTemporaryContent(new_node.Content);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [id]);

  function send_prompt(prompt_name: string) {
    let text =
      "" +
      prompt_prefix_dict.get(prompt_name) +
      temporaryContent +
      prompt_postfix_dict.get(prompt_name);
    socketRef.current?.send(
      JSON.stringify({ id: node.ID, type: "content", prompt: text })
    );
    setIsWaitingForAI(true);
  }

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  let user_header = (
    <Row>
      <Col style={{ margin: "5px", width: "100%" }}>
        <ButtonGroup>
          {isLoggedIn ? (
            <>
              <Button
                outline
                title="Log out"
                onClick={() => {
                  backendService.logout(setIsLoggedIn);
                }}
              >
                🔐 {userName}
              </Button>
              <Button outline href={BACKEND_URL + "treenodes/"}>
                ☰
              </Button>
            </>
          ) : (
            <Button
              outline
              title="Log in"
              onClick={() => {
                setOpenLoginModal(true);
              }}
            >
              🔓
            </Button>
          )}
        </ButtonGroup>
      </Col>
      <Col style={{ margin: "5px" }}>
        {editMode ? (
          <ButtonGroup>
            <ButtonDropdown toggle={toggleType} isOpen={typeDropdownOpen}>
              <DropdownToggle caret outline>
                Type: {node.Type}
              </DropdownToggle>
              <DropdownMenu>
                {type_list.map((type_name: String) => (
                  <DropdownItem
                    key={"" + type_name}
                    onClick={() => {
                      backendService
                        .change_type(node.ID, type_name.toString())
                        .then((res) => {
                          load_node(node.ID);
                        });
                    }}
                  >
                    {type_name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>

            <ButtonDropdown
              toggle={togglepChildren}
              isOpen={childrenDropdownOpen}
            >
              <DropdownToggle caret outline>
                +{node.Children.length}
              </DropdownToggle>
              <DropdownMenu>
                {node.Children.map((child: TreeNode) => (
                  <DropdownItem
                    onClick={() => load_node(child.ID)}
                    key={child.ID}
                  >
                    {child.Name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>

            <Button
              outline
              title="Add child node"
              onClick={() => {
                backendService
                  .create(
                    node.ID,
                    "***New node***",
                    "Default",
                    "Conent of your new node",
                    node.ID
                  )
                  .then((res) => load_node(node.ID));
              }}
            >
              ➕
            </Button>

            <ButtonDropdown
              toggle={togglepPrompt}
              isOpen={promptDropdownOpen}
              key="3kjkj3"
            >
              <DropdownToggle caret outline title="LLM assistent">
                {isWaitingForAI ? (
                  <Spinner size="sm">Loading...</Spinner>
                ) : (
                  <>🤖</>
                )}
              </DropdownToggle>
              <DropdownMenu key="3wt4wrw">
                {prompts.map((prompt_name: string) => (
                  <DropdownItem
                    key={"" + prompt_name}
                    onClick={() => {
                      send_prompt(prompt_name);
                    }}
                  >
                    {prompt_name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>

            <Button
              outline
              title="Copy to clipboard"
              onClick={() => {
                let text = copy_tree_to_clipboard(node);
                navigator.clipboard.writeText(JSON.stringify(text));
              }}
            >
              📋
            </Button>
            <Button
              outline
              title="Paste from clipboard"
              onClick={() => {
                if (navigator.clipboard !== undefined) {
                  navigator.clipboard.readText().then((clipText) => {
                    try {
                      const pasted = JSON.parse(clipText);
                      if ("Name" in pasted) {
                        backendService
                          .create(
                            node.ID,
                            pasted["Name"],
                            "Markdown",
                            clipText,
                            node.ID
                          )
                          .then((res) => load_node(node.ID));
                      }
                    } catch (e) {}
                  });
                }
              }}
            >
              📥
            </Button>

            <Button
              outline
              title="Delete node"
              onClick={() => {
                if (node.PathToRoot.length > 0) {
                  let parent: TreeNode =
                    node.PathToRoot[node.PathToRoot.length - 1];
                  const parent_id: string = parent.ID;
                  backendService.delete(node.ID).then((res) => {
                    load_node(parent_id);
                  });
                }
              }}
            >
              🗑️
            </Button>
          </ButtonGroup>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );

  if (node !== undefined) {
    node_header = (
      <Row>
        <Col style={{ margin: "5px" }}>
          <ButtonGroup style={editMode ? { width: "100%" } : {}}>
            {node.PathToRoot.slice(-3).map((waypoint: TreeNode) => (
              <Button
                key={"3f3f" + waypoint.ID}
                outline
                onClick={() => {
                  load_node(waypoint.ID);
                }}
              >
                <div style={{ whiteSpace: "nowrap" }}>
                  {cap_length(waypoint.Name)}
                </div>
              </Button>
            ))}
            {isLoggedIn && !editMode ? (
              <Button
                outline
                title="Toggle edit mode"
                onClick={() => {
                  togglepEditMode();
                }}
              >
                🛠️
              </Button>
            ) : (
              <></>
            )}
            {editMode ? (
              <>
                {editMode ? (
                  <Button
                    outline
                    title="Toggle edit mode"
                    onClick={() => {
                      togglepEditMode();
                    }}
                  >
                    👁️
                  </Button>
                ) : (
                  <></>
                )}
                <Input value={newNodeName} onChange={handleNameInputChange} />
                {newNodeName !== node.Name ? (
                  <Button
                    title="Save new name"
                    onClick={() => {
                      backendService
                        .change_name(node.ID, newNodeName)
                        .then((res) => load_node(node.ID));
                    }}
                  >
                    💾
                  </Button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Button disabled>{node.Name}</Button>
            )}
          </ButtonGroup>
          {isConnected ? <></> : <Spinner size="sm">Connecting...</Spinner>}
        </Col>
      </Row>
    );
  }

  const login_modal = (
    <Modal
      isOpen={openLoginModal}
      toggle={() => setOpenLoginModal(!openLoginModal)}
    >
      <ModalHeader toggle={() => setOpenLoginModal(!openLoginModal)}>
        Login
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="username">Username:</Label>
          <Input
            type="text"
            name="username"
            onChange={(e: ChangeEvent) => {
              const target = e.target as HTMLInputElement;
              setUsername(target.value);
            }}
            value={username}
          />
        </FormGroup>

        <FormGroup>
          <Label for="password">Password:</Label>
          <Input
            type="password"
            name="password"
            onChange={(e: ChangeEvent) => {
              const target = e.target as HTMLInputElement;
              setPassword(target.value);
            }}
            value={password}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            backendService.log_in(username, password, setIsLoggedIn);
            setOpenLoginModal(!openLoginModal);
            setUsername("");
            setPassword("");
          }}
        >
          Login
        </Button>{" "}
        <Button
          color="secondary"
          onClick={() => setOpenLoginModal(!openLoginModal)}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <Container style={{ marginTop: "20px" }}>
      {login_modal}
      {user_header}
      {node_header}
      <Row>
        <Col style={{ margin: "5px" }}>
          {editMode
            ? Editor(node, load_node, handleTempContentChange, temporaryContent)
            : module_by_type(
                node,
                load_node,
                handleTempContentChange,
                temporaryContent
              )}
        </Col>
      </Row>
    </Container>
  );
};

export default TypeRouter;
